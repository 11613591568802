import React, { useState } from 'react';
import { Box, IconButton, TextField } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import frLocale from 'date-fns/locale/fr';
import useMediaQuery from '@mui/material/useMediaQuery';

function ScheduleDatePicker({ selectedDate, onDateChange }) {
  const isMobile = useMediaQuery('(max-width: 600px)'); // Détecte si on est en mobile
  const [openPicker, setOpenPicker] = useState(false); // Contrôle l'ouverture du DateTimePicker

  const handleDateChange = (newDate) => {
    onDateChange(newDate); // Met à jour la date sélectionnée
    if (isMobile && newDate) {
      setOpenPicker(false); // Ferme après une sélection complète
    }
  };

  const handleOpenPicker = () => {
    setOpenPicker(true); // Ouvre le picker
  };

  const handleClosePicker = () => {
    setOpenPicker(false); // Ferme le picker
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
      {isMobile ? (
        <>
          <IconButton
            color="primary"
            onClick={handleOpenPicker}
            sx={{
              width: 48,
              height: 48,
              borderRadius: 2,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CalendarTodayIcon fontSize="inherit" />
          </IconButton>
          {openPicker && (
            <DateTimePicker
              value={selectedDate}
              onChange={handleDateChange}
              open={openPicker}
              onClose={handleClosePicker}
              closeOnSelect={false} // Garde le picker ouvert jusqu'à la validation
              componentsProps={{
                actionBar: {
                  actions: ['clear', 'accept'], // Inclut les actions Clear et Accept
                },
              }}
              renderInput={() => null} // Pas de champ visible en mode mobile
            />
          )}
        </>
      ) : (
        <DateTimePicker
          value={selectedDate}
          onChange={handleDateChange}
          closeOnSelect={false} // Comportement uniforme entre mobile et bureau
          componentsProps={{
            actionBar: {
              actions: ['clear', 'accept'], // Ajout des actions Clear et Accept
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <IconButton onClick={handleOpenPicker}>
                    <CalendarTodayIcon />
                  </IconButton>
                ),
              }}
              fullWidth
            />
          )}
        />
      )}
    </LocalizationProvider>
  );
}

export default ScheduleDatePicker;
