import React from 'react';
import { Button } from '@mui/material';

function PublishButton({ isPublishing, isPublished, onClick, disabled }) {
  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={onClick}
      disabled={disabled}
    >
      {isPublished ? "Déjà Publié" : "Publier"}
    </Button>
  );
}

export default PublishButton;
