// src/pages/Dashboard/Profile/EditProfileModal.js

import React, { useState } from 'react';
import { Box, Modal, TextField, Typography, Button, Grid, Avatar, IconButton } from '@mui/material';
import { updateDoc, doc } from 'firebase/firestore';
import { db, auth, storage } from '../../../../../config/firebaseConfig';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import EditIcon from '@mui/icons-material/Edit';

const EditProfileModal = ({ open, onClose, companyData, onSave }) => {
  const [formData, setFormData] = useState(companyData);
  const [ownerData, setOwnerData] = useState(companyData.owners ? companyData.owners[0] : {});
  const [logoUrl, setLogoUrl] = useState(companyData.logoUrl || '');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleOwnerChange = (e) => {
    const { name, value } = e.target;
    setOwnerData({ ...ownerData, [name]: value });
  };

  const handleLogoChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const user = auth.currentUser;
      const logoRef = ref(storage, `companyLogos/${user.uid}.jpg`);
      await uploadBytes(logoRef, file);
      const downloadURL = await getDownloadURL(logoRef);
      setLogoUrl(downloadURL);
      setFormData({ ...formData, logoUrl: downloadURL });
    }
  };

  const handleSave = async () => {
    const user = auth.currentUser;
    if (user) {
      const companyRef = doc(db, 'companies', user.uid);
      const updatedData = { ...formData, owners: [ownerData] };
      await updateDoc(companyRef, updatedData);
      onSave(updatedData);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          maxWidth: '600px',
          bgcolor: 'background.paper',
          p: 4,
          borderRadius: 2,
          boxShadow: 24,
        }}
      >
        <Typography variant="h5" sx={{ mb: 3 }}>
          Modifier le profil
        </Typography>

        {/* Logo de l'entreprise */}
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
          <input
            accept="image/*"
            type="file"
            id="logo-upload"
            style={{ display: 'none' }}
            onChange={handleLogoChange}
          />
          <label htmlFor="logo-upload">
            <IconButton component="span">
              <Avatar
                src={logoUrl}
                sx={{ width: 100, height: 100, cursor: 'pointer' }}
              />
              <EditIcon sx={{ position: 'absolute', bottom: 0, right: 0 }} />
            </IconButton>
          </label>
        </Box>

        <Grid container spacing={2}>
          {/* Informations de l'entreprise */}
          {Object.keys(formData).map((field) =>
            field !== 'logoUrl' && field !== 'owners' ? ( // Ignore les champs logo et propriétaires
              <Grid item xs={12} sm={6} key={field}>
                <TextField
                  label={field.charAt(0).toUpperCase() + field.slice(1)}
                  variant="outlined"
                  fullWidth
                  name={field}
                  value={formData[field] || ''}
                  onChange={handleChange}
                />
              </Grid>
            ) : null
          )}

          {/* Informations du propriétaire principal */}
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
              Informations du propriétaire
            </Typography>
          </Grid>
          {['firstName', 'lastName', 'email', 'role'].map((field) => (
            <Grid item xs={12} sm={6} key={field}>
              <TextField
                label={field.charAt(0).toUpperCase() + field.slice(1)}
                variant="outlined"
                fullWidth
                name={field}
                value={ownerData[field] || ''}
                onChange={handleOwnerChange}
              />
            </Grid>
          ))}
        </Grid>

        <Box display="flex" justifyContent="flex-end" mt={3}>
          <Button onClick={onClose} color="inherit" sx={{ mr: 2 }}>
            Annuler
          </Button>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Enregistrer
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default EditProfileModal;
