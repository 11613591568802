import React from 'react';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

function DeleteButton({ onClick, disabled }) {
  return (
    <IconButton
      disabled={disabled}
      onClick={onClick}
      sx={{
        color: 'error.main',
        bgcolor: 'rgba(255, 255, 255, 0.8)',
        '&:hover': {
          bgcolor: 'rgba(255, 255, 255, 1)',
        },
      }}
    >
      <DeleteIcon />
    </IconButton>
  );
}

export default DeleteButton;
