// src/services/companyService.js
import { db } from "../../config/firebaseConfig";
import { doc, getDoc } from 'firebase/firestore';

// Fonction pour récupérer les informations de l'entreprise depuis Firestore
export const fetchCompanyInfo = async (companyId) => {
  try {
    const companyRef = doc(db, 'companies', companyId);
    const companyDoc = await getDoc(companyRef);

    if (companyDoc.exists()) {
      return companyDoc.data();
    } else {
      console.error("La société n'existe pas dans la base de données.");
      return null;
    }
  } catch (error) {
    console.error('Erreur lors de la récupération des informations de la société:', error);
    return null;
  }
};
