// src/pages/Dashboard/Profile/ProfilePage.js
import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Container } from '@mui/material';
import { auth } from '../../../../config/firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import ProfileCard from './components/ProfileCard';
import SocialNetMediaConfigurationPage from '../SocialMedia/SocialMediaAuthorization/SocialNetMediaConfigurationPage';
import { fetchCompanyInfo } from '../../../../services/firebase/companyService';

function ProfilePage() {
  const [companyName, setCompanyName] = useState('');
  const [companyLogoUrl, setCompanyLogoUrl] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        const companyId = currentUser.uid; // ID de la compagnie dans Firestore
        const companyInfo = await fetchCompanyInfo(companyId);

        if (companyInfo) {
          setCompanyName(companyInfo.name || '');
          setCompanyLogoUrl(companyInfo.logoUrl || '');
        }
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center',  padding: '16px', overflowY: 'auto', height: '100%' }}>
      <Container maxWidth="md" sx={{ mt: 5, mb: 5 }}>
        <ProfileCard
          displayName={companyName}
          photoURL={companyLogoUrl}
        />

        {/* Section pour la configuration des réseaux sociaux */}
        <Box sx={{ mt: 5 }}>
          <SocialNetMediaConfigurationPage />
        </Box>
      </Container>
    </Box>
  );
}

export default ProfilePage;
