// src/pages/Dashboard/Profile/components/ProfileCard.js

import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Avatar,
  Button,
  Box,
  CircularProgress,
} from '@mui/material';
import { auth, onAuthStateChanged } from '../../../../../config/firebaseConfig';
import EditProfileModal from './EditProfileModal';
import { fetchCompanyInfo } from '../../../../../services/firebase/companyService';

const ProfileCard = () => {
  const [companyData, setCompanyData] = useState(null);
  const [ownerData, setOwnerData] = useState(null); // Nouvel état pour les informations du propriétaire
  const [loading, setLoading] = useState(true);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        fetchCompanyData(currentUser.uid);
      }
    });
    return () => unsubscribe();
  }, []);

  const fetchCompanyData = async (companyId) => {
    setLoading(true);
    const companyInfo = await fetchCompanyInfo(companyId);

    if (companyInfo) {
      setCompanyData(companyInfo);

      // Vérifie si l'entreprise a un propriétaire principal dans le champ owners
      if (companyInfo.owners && companyInfo.owners.length > 0) {
        setOwnerData(companyInfo.owners[0]); // Prend le premier propriétaire comme principal
      }
    } else {
      console.error("Les informations de l'entreprise n'ont pas pu être récupérées.");
    }
    setLoading(false);
  };

  const handleOpenModal = () => setIsEditModalOpen(true);
  const handleCloseModal = () => setIsEditModalOpen(false);

  const handleSaveChanges = (updatedData) => {
    setCompanyData(updatedData);
    handleCloseModal();
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Card
      sx={{
        maxWidth: 600,
        mx: 'auto',
        mt: 5,
        p: 3,
        boxShadow: 3,
        borderRadius: 3,
        textAlign: 'center',
        backgroundColor: '#f9f9f9',
      }}
    >
      <CardContent>
        {/* Logo de l'entreprise */}
        <Avatar
          src={companyData?.logoUrl || ''}
          sx={{
            width: 100,
            height: 100,
            mb: 2,
            border: '3px solid #ddd',
          }}
        />

        {/* Nom et Slogan de l'entreprise */}
        <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#333' }}>
          {companyData?.name || 'Nom de l’entreprise'}
        </Typography>
        
        <Typography variant="subtitle1" sx={{ color: 'text.secondary', mt: 1 }}>
          {companyData?.slogan || 'Slogan de l’entreprise'}
        </Typography>
        
        <Typography variant="body1" sx={{ color: 'text.secondary', mt: 2 }}>
          {companyData?.description || 'Description de l’entreprise'}
        </Typography>

        {/* Informations du propriétaire */}
        {ownerData && (
          <Box sx={{ mt: 3, p: 2, backgroundColor: '#f0f0f0', borderRadius: 2 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333' }}>
              Propriétaire Principal
            </Typography>
            <Typography variant="body1" sx={{ color: 'text.secondary', mt: 1 }}>
              {ownerData.firstName} {ownerData.lastName} - {ownerData.role}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary', mt: 1 }}>
              {ownerData.email}
            </Typography>
          </Box>
        )}

        {/* Autres informations de l'entreprise */}
        <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: '#555' }}>
              Téléphone :
            </Typography>
            <Typography variant="body2">{companyData?.phone || 'Non renseigné'}</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: '#555' }}>
              Email :
            </Typography>
            <Typography variant="body2">{companyData?.email || 'Non renseigné'}</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: '#555' }}>
              Site Web :
            </Typography>
            <Typography variant="body2">{companyData?.website || 'Non renseigné'}</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: '#555' }}>
              N° fiscal :
            </Typography>
            <Typography variant="body2">{companyData?.taxId || 'Non renseigné'}</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: '#555' }}>
              N° d’enregistrement :
            </Typography>
            <Typography variant="body2">{companyData?.registrationNumber || 'Non renseigné'}</Typography>
          </Box>
        </Box>

        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 4, px: 4, py: 1, borderRadius: 2 }}
          onClick={handleOpenModal}
        >
          Modifier le profil
        </Button>

        <EditProfileModal
          open={isEditModalOpen}
          onClose={handleCloseModal}
          companyData={companyData}
          onSave={handleSaveChanges}
        />
      </CardContent>
    </Card>
  );
};

export default ProfileCard;
