import React from 'react';
import { IconButton, Menu, MenuItem, Checkbox } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

function SocialMediaActions({ isMobile, selectedPlatforms, togglePlatform, anchorEl, handleMenuOpen, handleMenuClose }) {
  return (
    <>
      {isMobile ? (
        // Mode téléphone - Sélection de plateformes via un bouton menu
        <>
          <IconButton
            onClick={handleMenuOpen}
            sx={{ color: 'primary.main' }}
          >
            <ShareIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem onClick={() => togglePlatform('facebook')}>
              <Checkbox checked={selectedPlatforms.facebook} />
              <FacebookIcon sx={{ ml: 1 }} /> Facebook
            </MenuItem>
            <MenuItem onClick={() => togglePlatform('instagram')}>
              <Checkbox checked={selectedPlatforms.instagram} />
              <InstagramIcon sx={{ ml: 1 }} /> Instagram
            </MenuItem>
            <MenuItem onClick={() => togglePlatform('twitter')}>
              <Checkbox checked={selectedPlatforms.twitter} />
              <TwitterIcon sx={{ ml: 1 }} /> Twitter
            </MenuItem>
            <MenuItem onClick={() => togglePlatform('linkedin')}>
              <Checkbox checked={selectedPlatforms.linkedin} />
              <LinkedInIcon sx={{ ml: 1 }} /> LinkedIn
            </MenuItem>
          </Menu>
        </>
      ) : (
        // Mode bureau - Boutons individuels des réseaux sociaux
        <>
          <IconButton
            onClick={() => togglePlatform('facebook')}
            sx={{
              color: selectedPlatforms.facebook ? 'primary.main' : 'grey.500',
            }}
          >
            <FacebookIcon />
          </IconButton>
          <IconButton
            onClick={() => togglePlatform('instagram')}
            sx={{
              color: selectedPlatforms.instagram ? 'primary.main' : 'grey.500',
            }}
          >
            <InstagramIcon />
          </IconButton>
          <IconButton
            onClick={() => togglePlatform('twitter')}
            sx={{
              color: selectedPlatforms.twitter ? 'primary.main' : 'grey.500',
            }}
          >
            <TwitterIcon />
          </IconButton>
          <IconButton
            onClick={() => togglePlatform('linkedin')}
            sx={{
              color: selectedPlatforms.linkedin ? 'primary.main' : 'grey.500',
            }}
          >
            <LinkedInIcon />
          </IconButton>
        </>
      )}
    </>
  );
}

export default SocialMediaActions;
