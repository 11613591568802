import React, { useState, useEffect } from 'react';
import { auth } from '../../config/firebaseConfig'; // Import Firebase Auth
import { signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import { useNavigate, Link } from 'react-router-dom';
import {
  Box,
  Button,
  TextField,
  Typography,
  Card,
  CardContent,
  CardActions,
  Grid,
  Container,
  Avatar,
  Alert,
  CardMedia,
  IconButton
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CloseIcon from '@mui/icons-material/Close';
import LoginImage from '../../assets/images/my-background-image.jpg'; // Chemin de l'image

function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  
  // Vérifie si l'utilisateur est déjà connecté
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // Si l'utilisateur est connecté, stocke le token dans le local storage
        const token = await user.getIdToken();
        localStorage.setItem('userToken', token);

        // Redirige vers le dashboard
        navigate('/dashboard');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    
    try {
      // Connexion de l'utilisateur avec Firebase Auth
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Récupère le token de l'utilisateur et le stocke dans le local storage
      const token = await user.getIdToken();
      localStorage.setItem('userToken', token);

      // Redirection après connexion réussie
      navigate('/dashboard');
    } catch (error) {
      console.error('Erreur lors de la connexion:', error);
      setError('Email ou mot de passe incorrect. Veuillez réessayer.');
    }
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 5, mb: 5 }}>
      <Card sx={{ boxShadow: 3, borderRadius: 4, position: 'relative' }}>
        {/* Bouton de fermeture pour retourner à l'accueil */}
        <IconButton
          onClick={() => navigate('/')}
          sx={{ position: 'absolute', top: 10, right: 10 }}
        >
          <CloseIcon />
        </IconButton>

        <CardMedia
          component="img"
          height="200"
          image={LoginImage}
          alt="Connexion"
          sx={{ borderTopLeftRadius: 4, borderTopRightRadius: 4 }}
        />
        <CardContent>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5" align="center" sx={{ mb: 2 }}>
              Connectez-vous
            </Typography>

            {/* Formulaire de connexion */}
            <Box component="form" onSubmit={handleLogin} sx={{ width: '100%' }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Adresse Email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Mot de passe"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sx={{ textAlign: 'right' }}>
                  {/* Lien vers mot de passe oublié */}
                  <Typography
                    variant="body2"
                    component={Link}
                    to="/forgot-password"
                    sx={{ textDecoration: 'none', color: 'primary.main' }}
                  >
                    Mot de passe oublié ?
                  </Typography>
                </Grid>
              </Grid>

              {error && (
                <Alert severity="error" sx={{ mt: 2 }}>
                  {error}
                </Alert>
              )}
            </Box>
          </Box>
        </CardContent>
        <CardActions>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ m: 2 }}
            onClick={handleLogin}
          >
            Connexion
          </Button>
        </CardActions>
      </Card>
    </Container>
  );
}

export default LoginPage;
