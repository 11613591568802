import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3E885B', // Vert plus équilibré et foncé
    },
    secondary: {
      main: '#8BB174', // Vert légèrement plus équilibré pour les accents
    },
    background: {
      default: '#F9F9F9', // Blanc cassé neutre
      paper: '#FFFFFF', // Blanc pur pour les surfaces
    },
    text: {
      primary: '#1A202C', // Noir doux pour une meilleure lisibilité
      secondary: '#3E885B', // Vert foncé pour les éléments mis en avant
    },
    info: {
      main: '#4CAF50', // Vert pour des messages d'information
    },
    success: {
      main: '#6FA96F', // Vert plus équilibré pour des messages de succès
    },
    error: {
      main: '#E4572E', // Rouge orangé pour des erreurs
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", sans-serif',
    h1: {
      fontSize: '2.75rem',
      fontWeight: 700,
      color: '#1A202C',
    },
    h2: {
      fontSize: '2.25rem',
      fontWeight: 600,
      color: '#1A202C',
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.7,
      color: '#1A202C',
    },
    button: {
      fontWeight: 600,
      textTransform: 'none',
    },
  },
  components: {
    // Boutons
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
          textTransform: 'none',
          fontWeight: 'bold',
          padding: '10px 22px',
          transition: 'all 0.3s ease',
          '&:hover': {
            backgroundColor: '#A7D676',
            color: '#FFFFFF',
          },
        },
        containedPrimary: {
          backgroundColor: '#3E885B',
          color: '#FFFFFF',
          '&:hover': {
            backgroundColor: '#336A47',
          },
        },
        outlined: {
          borderColor: '#3E885B',
          color: '#3E885B',
          '&:hover': {
            borderColor: '#336A47',
            backgroundColor: 'rgba(62, 136, 91, 0.1)',
          },
        },
      },
    },
    // Cartes
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
          boxShadow: '0px 6px 16px rgba(0, 0, 0, 0.1)',
          padding: '20px',
          background: 'linear-gradient(135deg, #FFFFFF, #F3F4F6)',
          transition: 'transform 0.3s ease, box-shadow 0.3s ease',
          '&:hover': {
            transform: 'scale(1.02)',
            boxShadow: '0px 10px 24px rgba(0, 0, 0, 0.15)',
          },
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          backgroundColor: '#F1F5F9',
          borderBottom: '1px solid #E2E8F0',
          padding: '20px',
        },
        title: {
          fontSize: '1.6rem',
          fontWeight: 'bold',
          color: '#1A202C',
        },
        subheader: {
          fontSize: '1rem',
          color: '#3E885B',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '20px',
          fontSize: '1rem',
          color: '#1A202C',
        },
      },
    },
    // Barre de navigation
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#3E885B',
          color: '#FFFFFF',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
          [`@media (max-width:600px)`]: {
            backgroundColor: '#336A47', // Ajustement de la couleur pour les petits écrans
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          justifyContent: 'space-between',
          padding: '0 24px',
        },
      },
    },
    // Menu
    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
          margin: '4px 0',
          padding: '10px 18px',
          '&:hover': {
            backgroundColor: '#E8F5E9',
          },
          '&.Mui-selected': {
            backgroundColor: '#A7D676',
            color: '#FFFFFF',
          },
        },
      },
    },
    // Entrées de texte
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
          backgroundColor: '#FFFFFF',
          '&.Mui-focused': {
            boxShadow: '0px 0px 10px rgba(62, 136, 91, 0.5)',
          },
        },
        input: {
          padding: '12px 18px',
        },
      },
    },
    // Drawer (Menu latéral)
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#F9F9F9',
          boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
          padding: '16px',
        },
      },
    },
    // Diviseurs (séparateurs)
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: '#E2E8F0',
          margin: '10px 0',
        },
      },
    },
    // Progressions circulaires (loaders)
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: '#3E885B',
        },
      },
    },
  },
});

export default theme;
