import React from 'react';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

const MenuItemButton = ({ icon, text, onClick }) => (
  <ListItemButton
    onClick={onClick}
    sx={{
      '&:hover': {
        backgroundColor: '#F5F5F5', // Couleur au survol
      },
      '&:focus-visible': {
        outline: 'none', // Supprime les contours de focus excessifs
      },
    }}
  >
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText primary={text} />
  </ListItemButton>
);

export default MenuItemButton;
