// CancelEditDialog.js
import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

function CancelEditDialog({ isOpen, onClose, onConfirm }) {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Annuler les modifications</DialogTitle>
      <DialogContent>
        <DialogContentText>Êtes-vous sûr de vouloir annuler vos modifications ?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Non, revenir
        </Button>
        <Button onClick={onConfirm} color="secondary" autoFocus>
          Oui
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CancelEditDialog;
