import axios from 'axios';
import { auth } from '../../config/firebaseConfig';
import { saveDraftPost, fetchDraftPosts } from '../firebase/draftPostService';

// Fonction pour générer un nouveau post
export const generatePost = async (textContent, mediaFiles, selectedDate,setGeneratedPosts, setTextContent, setMediaFiles, setSelectedDate) => {
  try {
    // Ajouter un post temporaire pour l'affichage en cours de génération
    const tempPost = { content: '', mediaFiles: [...mediaFiles], selectedDate: '', isGenerating: true, isPublished: false };
    setGeneratedPosts((prevPosts) => [...prevPosts, tempPost]);

    // Obtenez le token d'authentification de l'utilisateur connecté
    const token = await auth.currentUser.getIdToken();

    // Envoyer la requête pour générer le contenu avec l'API OpenAI
    const response = await axios.post(
      'https://generatepostopenai-wcpxh4udwa-uc.a.run.app',
      {
        textContent,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`, // Ajout de l'en-tête Authorization avec le token Firebase
        },
      }
    );

    const newContent = response.data.response;

    // Enregistrer le brouillon dans Firebase avec le contenu généré et le prompt utilisé
    await saveDraftPost(newContent, mediaFiles, selectedDate || [], null, textContent, selectedDate);

    // Récupérer la liste mise à jour des brouillons depuis Firebase
    const updatedDrafts = await fetchDraftPosts();

    // Mettre à jour l'état avec les brouillons actualisés
    setGeneratedPosts(updatedDrafts);

    // Réinitialiser les champs de texte et de média
    setTextContent('');
    setMediaFiles([]);
    setSelectedDate('');
  } catch (error) {
    console.error('Erreur lors de la génération de la publication :', error);

    // Supprimer le post temporaire en cas d'erreur
    setGeneratedPosts((prevPosts) => prevPosts.slice(0, -1));
  }
};

// Fonction pour régénérer un post existant
export const regeneratePost = async (draftId, generatedPosts, setGeneratedPosts) => {
  try {
    // Trouver le post à régénérer
    const post = generatedPosts.find((p) => p.id === draftId);
    if (!post) {
      console.error('Le post à régénérer est introuvable.');
      return;
    }

    // Marquer le post comme étant en cours de génération
    setGeneratedPosts((prevPosts) =>
      prevPosts.map((p) => (p.id === draftId ? { ...p, isGenerating: true } : p))
    );

    // Obtenez le token d'authentification de l'utilisateur connecté
    const token = await auth.currentUser.getIdToken();

    // Envoyer la requête pour régénérer le contenu avec l'API OpenAI
    const response = await axios.post(
      'https://generatepostopenai-wcpxh4udwa-uc.a.run.app',
      {
        textContent: post.promptContent || post.content, // Utiliser le prompt original si disponible
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const newContent = response.data.response;

    // Mettre à jour le brouillon dans Firebase avec le nouveau contenu généré
    await saveDraftPost(newContent, post.mediaFiles || [], draftId, post.promptContent);

    // Récupérer la liste mise à jour des brouillons depuis Firebase
    const updatedDrafts = await fetchDraftPosts();

    // Mettre à jour l'état avec les brouillons actualisés
    setGeneratedPosts(updatedDrafts);
  } catch (error) {
    console.error('Erreur lors de la régénération de la publication :', error);

    // Réinitialiser l'état de génération en cas d'erreur
    setGeneratedPosts((prevPosts) =>
      prevPosts.map((p) => (p.id === draftId ? { ...p, isGenerating: false } : p))
    );
  }
};

// Fonction pour régénérer une partie d'un post existant
export const regeneratePartialPost = async (fullText, selectedText) => {
  try {
    // Obtenir le token d'authentification de l'utilisateur connecté
    const token = await auth.currentUser.getIdToken();

    // Envoyer la requête pour régénérer partiellement le contenu avec l'API OpenAI
    const response = await axios.post(
      'https://regeneratepartialpostopenai-wcpxh4udwa-uc.a.run.app',
      {
        fullText,
        selectedText,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // Retourner uniquement le contenu régénéré sans sauvegarder
    return response.data.response;

  } catch (error) {
    console.error('Erreur lors de la régénération partielle de la publication :', error);
    return null; // En cas d'erreur, retourner null pour indiquer un échec
  }
};
