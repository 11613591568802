// src/pages/Dashboard/sections/PostHistory/PostHistoryPage.js
import React, { useState, useEffect } from 'react';
import { Container, Grid, CircularProgress, Box } from '@mui/material';
import { getFacebookPostHistory } from '../../../../services/firebase/facebookPostHistoryService';
import { getFacebookPostDetails } from '../../../../services/socialNetworks/facebook/facebookPostService';
import { fetchFacebookPageInfo } from '../../../../services/socialNetworks/facebook/facebookPageService';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../../../config/firebaseConfig';
import FacebookPostCard from '../../../../components/Card/SocialMediaCard/FacebookPostCard';

function PostHistoryPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsUserAuthenticated(true);
      } else {
        setIsUserAuthenticated(false);
        setIsLoading(false);
      }
    });

    return () => unsubscribe(); // Clean up l'observateur lorsque le composant est démonté
  }, []);

  useEffect(() => {
    if (!isUserAuthenticated) {
      return;
    }

    const fetchPostHistory = async () => {
      try {
        // Récupérer les informations de la page Facebook
        const { pageId, pageAccessToken } = await fetchFacebookPageInfo();

        if (!pageId || !pageAccessToken) {
          throw new Error('Page ID ou Access Token manquant.');
        }

        // Récupérer l'historique des publications de l'utilisateur connecté
        const postHistory = await getFacebookPostHistory();

        // Récupérer les détails des publications via l'API Facebook
        const postDataPromises = postHistory.map((post) =>
        
          getFacebookPostDetails(pageId, post.postId, pageAccessToken)
        );

        const postsData = await Promise.all(postDataPromises);
        setPosts(postsData.filter((post) => post !== null));
        console.log(postsData)
      } catch (error) {
        console.error('Erreur lors de la récupération de l’historique des publications :', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPostHistory();
  }, [isUserAuthenticated]);

  if (isLoading) {
    return (
      <Container maxWidth="md" sx={{ mt: 5, mb: 5, position: 'relative' }}>
        <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
          <CircularProgress />
        </Grid>
      </Container>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', padding: 3 }}>
      <Grid container spacing={2} sx={{ width: '100%', maxWidth: '800px', margin: '0 auto' }}>
        {posts.length > 0 ? (
          posts.map((post, index) => (
            <Grid item xs={12} key={index}>
              <Box sx={{ mb: 2 }}>
                <FacebookPostCard post={post} />
              </Box>
            </Grid>
          ))
        ) : (
          <Box sx={{ textAlign: 'center', width: '100%', marginTop: 4 }}>
            Aucune publication trouvée.
          </Box>
        )}
      </Grid>
    </Box>
  );
}

export default PostHistoryPage;
