import React from 'react';
import { Card, CardContent, CardActions, Button, Typography, Box } from '@mui/material';

function SocialMediaAuthorizationCard({ networkName, networkLogo, description, onAuthorize }) {
    return (
        <Card sx={{ maxWidth: 345, boxShadow: 3, mb: 2 }}>
            <CardContent sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Box>{networkLogo}</Box>
                <Typography variant="h6">{networkName}</Typography>
            </CardContent>
            {description && (
                <CardContent>
                    <Typography variant="body2" color="textSecondary" component="div" sx={{ mb: 2 }}>
                        {description}
                    </Typography>
                </CardContent>
            )}
            <CardActions>
                <Button variant="contained" color="primary" onClick={onAuthorize} fullWidth>
                    Autoriser {networkName}
                </Button>
            </CardActions>
        </Card>
    );
}

export default SocialMediaAuthorizationCard;
