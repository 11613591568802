// PreferencesModal.js
import React, { useState, useEffect, useMemo } from 'react';
import { Box, Modal, Typography, TextField as MuiTextField, Button, Slider, Select, MenuItem, Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { savePreferences, fetchPreferences } from '../../services/firebase/preferencesService';

const PreferencesModal = ({ open, onClose }) => {
    const [tone, setTone] = useState('');
    const [brandValues, setBrandValues] = useState('');
    const [languageStyle, setLanguageStyle] = useState('');
    const [targetAudience, setTargetAudience] = useState('');
    const [callToActionStyle, setCallToActionStyle] = useState('');
    const [visualStyle, setVisualStyle] = useState('');
    const [humorPersonality, setHumorPersonality] = useState('');
    const [signature, setSignature] = useState('');
    const [textLength, setTextLength] = useState(1);
    const [smileyUsage, setSmileyUsage] = useState(1);
    const [language, setLanguage] = useState('Francais');
    const [linkProductOrService, setLinkProductOrService] = useState('');
    const [hashtags, setHashtag] = useState('');
    const [favoriteThemes, setFavoriteThemes] = useState('');

    // Utilisez useMemo pour mémoriser les tableaux, afin qu'ils ne changent pas à chaque rendu
    const textLengthMarks = useMemo(() => [
        { value: 0, label: 'Très Court' },
        { value: 1, label: 'Court' },
        { value: 2, label: 'Moyen' },
        { value: 3, label: 'Long' },
        { value: 4, label: 'Très Long' }
    ], []);

    const smileyUsageMarks = useMemo(() => [
        { value: 0, label: 'Aucun' },
        { value: 1, label: 'Modéré' },
        { value: 2, label: 'Beaucoup' }
    ], []);

    useEffect(() => {
        const loadPreferences = async () => {
            try {
                const preferences = await fetchPreferences();
                if (preferences) {
                    setTone(preferences.tone || '');
                    setBrandValues(preferences.brandValues || '');
                    setLanguageStyle(preferences.languageStyle || '');
                    setTargetAudience(preferences.targetAudience || '');
                    setCallToActionStyle(preferences.callToActionStyle || '');
                    setVisualStyle(preferences.visualStyle || '');
                    setHumorPersonality(preferences.humorPersonality || '');
                    setSignature(preferences.signature || '');
                    const textLengthIndex = textLengthMarks.findIndex(mark => mark.label.toLowerCase() === preferences.textLength.toLowerCase());
                    setTextLength(textLengthIndex !== -1 ? textLengthIndex : 1);
                    const smileyUsageIndex = smileyUsageMarks.findIndex(mark => mark.label.toLowerCase() === preferences.smileyUsage.toLowerCase());
                    setSmileyUsage(smileyUsageIndex !== -1 ? smileyUsageIndex : 1);
                    setLanguage(preferences.language || 'Francais');
                    setLinkProductOrService(preferences.linkProductOrService || '');
                    setHashtag(preferences.hashtags || '');
                    setFavoriteThemes(preferences.favoriteThemes || '');
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des préférences :', error);
            }
        };

        if (open) {
            loadPreferences();
        }
    }, [open, textLengthMarks, smileyUsageMarks]);    

    const handleSavePreferences = async () => {
        const preferences = {
            textLength: textLengthMarks[textLength].label, // Sauvegarder le label correspondant
            tone,
            brandValues,
            languageStyle,
            targetAudience,
            callToActionStyle,
            visualStyle,
            humorPersonality,
            signature,
            smileyUsage: smileyUsageMarks[smileyUsage].label, // Sauvegarder le label correspondant
            language,
            linkProductOrService,
            hashtags,
            favoriteThemes
        };
        try {
            await savePreferences(preferences);
            console.log('Préférences enregistrées avec succès');
            onClose();
        } catch (error) {
            console.error('Erreur lors de l’enregistrement des préférences :', error);
        }
    };

    const handleTextLengthSliderChange = (e, value) => {
        setTextLength(Number(value)); // Assurer que la valeur est bien un nombre
    };

    const handleSmileyUsageSliderChange = (e, value) => {
        setSmileyUsage(Number(value)); // Assurer que la valeur est bien un nombre
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '100%', sm: '600px' },
                    maxHeight: '90vh',
                    overflowY: 'auto',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 4,
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                    <Typography variant="h6" component="h2">
                        Préférences de Publication
                    </Typography>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Grid container spacing={3}>
                    {/* Longueur du texte */}
                    <Grid item xs={12} sm={6}>
                        <Typography gutterBottom>Longueur du texte</Typography>
                        <Slider
                            value={textLength}
                            step={1}
                            marks={textLengthMarks}
                            min={0}
                            max={3}
                            onChange={handleTextLengthSliderChange}
                            valueLabelDisplay="off"
                            
                        />
                    </Grid>

                    {/* Utilisation des smileys */}
                    <Grid item xs={12} sm={6}>
                        <Typography gutterBottom>Utilisation des smileys</Typography>
                        <Slider
                            value={smileyUsage}
                            step={1}
                            marks={smileyUsageMarks}
                            min={0}
                            max={2}
                            onChange={handleSmileyUsageSliderChange}
                            valueLabelDisplay="off"
                            
                        />
                    </Grid>
                    {/* Ton de communication */}
                    <Grid item xs={12} sm={6}>
                        <Typography gutterBottom>Ton de communication</Typography>
                        <Select
                            fullWidth
                            value={tone}
                            onChange={(e) => setTone(e.target.value)}
                            variant="outlined"
                        >
                            <MenuItem value="">Aucun</MenuItem>
                            <MenuItem value="Amical">Amical</MenuItem>
                            <MenuItem value="Professionnel">Professionnel</MenuItem>
                            <MenuItem value="Humoristique">Humoristique</MenuItem>
                        </Select>
                    </Grid>

                    {/* Style de langage */}
                    <Grid item xs={12} sm={6}>
                        <Typography gutterBottom>Style de langage</Typography>
                        <Select
                            fullWidth
                            value={languageStyle}
                            onChange={(e) => setLanguageStyle(e.target.value)}
                            variant="outlined"
                        >
                            <MenuItem value="">Aucun</MenuItem>
                            <MenuItem value="Décontracté">Décontracté</MenuItem>
                            <MenuItem value="Formel">Formel</MenuItem>
                            <MenuItem value="Technique">Technique</MenuItem>
                        </Select>
                    </Grid>

                    {/* Style Visuel des Publications */}
                    <Grid item xs={12} sm={6}>
                        <Typography gutterBottom>Style Visuel des Publications</Typography>
                        <Select
                            fullWidth
                            value={visualStyle}
                            onChange={(e) => setVisualStyle(e.target.value)}
                            variant="outlined"
                        >
                            <MenuItem value="">Aucun</MenuItem>
                            <MenuItem value="Minimaliste">Minimaliste</MenuItem>
                            <MenuItem value="Coloré">Coloré</MenuItem>
                            <MenuItem value="Élégant">Élégant</MenuItem>
                        </Select>
                    </Grid>

                    {/* Humour et Personnalité */}
                    <Grid item xs={12} sm={6}>
                        <Typography gutterBottom>Humour et Personnalité</Typography>
                        <Select
                            fullWidth
                            value={humorPersonality}
                            onChange={(e) => setHumorPersonality(e.target.value)}
                            variant="outlined"
                        >
                            <MenuItem value="">Aucun</MenuItem>
                            <MenuItem value="Ironique">Ironique</MenuItem>
                            <MenuItem value="Ludique">Ludique</MenuItem>
                            <MenuItem value="Sérieux">Sérieux</MenuItem>
                        </Select>
                    </Grid>

                    {/* Valeurs de la marque */}
                    <Grid item xs={12} sm={6}>
                        <Typography gutterBottom>Valeurs de la marque</Typography>
                        <Select
                            fullWidth
                            value={brandValues}
                            onChange={(e) => setBrandValues(e.target.value)}
                            variant="outlined"
                        >
                            <MenuItem value="">Aucun</MenuItem>
                            <MenuItem value="Innovation">Innovation</MenuItem>
                            <MenuItem value="Durabilité">Durabilité</MenuItem>
                            <MenuItem value="Authenticité">Authenticité</MenuItem>
                        </Select>
                    </Grid>

                    {/* Thématiques Favorites */}
                    <Grid item xs={12} sm={6}>
                        <MuiTextField
                            fullWidth
                            label="Thématiques Favorites"
                            value={favoriteThemes}
                            onChange={(e) => setFavoriteThemes(e.target.value)}
                            placeholder="Technologie, Santé, Voyage..."
                            variant="outlined"
                            margin="normal"
                        />
                    </Grid>

                    {/* Audience Cible */}
                    <Grid item xs={12} sm={6}>
                        <Typography gutterBottom>Audience Cible</Typography>
                        <Select
                            fullWidth
                            value={targetAudience}
                            onChange={(e) => setTargetAudience(e.target.value)}
                            variant="outlined"
                        >
                            <MenuItem value="">Aucun</MenuItem>
                            <MenuItem value="Jeunes adultes">Jeunes adultes</MenuItem>
                            <MenuItem value="Professionnels">Professionnels</MenuItem>
                            <MenuItem value="Parents">Parents</MenuItem>
                        </Select>
                    </Grid>

                    {/* Forme des Appels à l’Action */}
                    <Grid item xs={12} sm={6}>
                        <Typography gutterBottom>Forme des Appels à l’Action</Typography>
                        <Select
                            fullWidth
                            value={callToActionStyle}
                            onChange={(e) => setCallToActionStyle(e.target.value)}
                            variant="outlined"
                        >
                            <MenuItem value="">Aucun</MenuItem>
                            <MenuItem value="Incitatif">Incitatif</MenuItem>
                            <MenuItem value="Informel">Informel</MenuItem>
                            <MenuItem value="Direct">Direct</MenuItem>
                        </Select>
                    </Grid>

                    {/* Positionnement SEO / Hashtags */}
                    <Grid item xs={12} sm={6}>
                        <MuiTextField
                            fullWidth
                            label="Hashtags"
                            value={hashtags}
                            onChange={(e) => setHashtag(e.target.value)}
                            placeholder="#innovation, #durabilité, #tech..."
                            variant="outlined"
                            margin="normal"
                        />
                    </Grid>

                    {/* Lien avec les Produits et Services */}
                    <Grid item xs={12} sm={6}>
                        <MuiTextField
                            fullWidth
                            label="Lien avec les Produits et Services"
                            value={linkProductOrService}
                            onChange={(e) => setLinkProductOrService(e.target.value)}
                            placeholder="Mettre en avant les caractéristiques uniques, les bénéfices..."
                            variant="outlined"
                            margin="normal"
                        />
                    </Grid>

                    {/* Signature */}
                    <Grid item xs={12} sm={6}>
                        <MuiTextField
                            fullWidth
                            label="Signature"
                            placeholder="Votre ami de Viralist..."
                            value={signature}
                            onChange={(e) => setSignature(e.target.value)}
                            variant="outlined"
                            margin="normal"
                        />
                    </Grid>

                    {/* Langue */}
                    <Grid item xs={12} sm={6}>
                        <Typography gutterBottom>Langue</Typography>
                        <Select
                            fullWidth
                            value={language}
                            onChange={(e) => setLanguage(e.target.value)}
                            variant="outlined"
                        >
                            <MenuItem value="Francais">Français</MenuItem>
                            <MenuItem value="Anglais">Anglais</MenuItem>
                            <MenuItem value="Espagnol">Espagnol</MenuItem>
                        </Select>
                    </Grid>
                </Grid>

                <Button
                    variant="contained"
                    onClick={handleSavePreferences}
                    sx={{ mt: 4, display: 'block', ml: 'auto', backgroundColor: '#1976d2', color: '#fff' }}
                >
                    Enregistrer
                </Button>
            </Box>
        </Modal>
    );
}
export default PreferencesModal;