import React from 'react';
import { Box, IconButton } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteButton from './DeleteButton';

function CardActionsTopSection({ isEditing, isPublished, onDelete, onEditToggle, onCancelEdit, onSave }) {
  return (
    <Box sx={{ position: 'absolute', top: 8, right: 8, zIndex: 2, display: 'flex', gap: 1 }}>
      {isEditing && (
        <IconButton color="secondary" onClick={onCancelEdit}>
          <CancelIcon />
        </IconButton>
      )}
      {isEditing ? (
        <IconButton color="secondary" onClick={onSave}>
          <SaveIcon />
        </IconButton>
      ) : (
        <IconButton color="primary" onClick={onEditToggle}>
          <EditIcon />
        </IconButton>
      )}
      <DeleteButton onClick={onDelete} disabled={isPublished || isEditing} />
    </Box>
  );
}

export default CardActionsTopSection;
