// src/services/socialNetworks/facebook/facebookPostService.js
import axios from 'axios';
import { showNotification } from '../../Notification/notificationService'; // Assurez-vous du bon chemin vers le service

const FB_API_VERSION = 'v12.0';

// Fonction pour récupérer les détails d'une publication Facebook à partir de son ID
export const getFacebookPostDetails = async (pageId, postId, accessToken) => {
  try {
    const postUrl = `https://graph.facebook.com/${FB_API_VERSION}/${postId}`;
    const response = await axios.get(postUrl, {
      params: {
        access_token: accessToken,
        fields: 'id,created_time,permalink_url,likes.summary(true),comments.summary(true),from{name,picture}',
      },
    });
    //fields: 'id,created_time,permalink_url,likes.summary(true),comments.summary(true),attachments{media,url},from{name,picture}'


    const postData = response.data;

    // Ajouter des logs pour vérifier le contenu des données récupérées
    console.log('Détails de la publication récupérés :', postData);

    // Assurer que `message` est toujours défini, même s'il n'est pas présent dans la réponse
    if (!postData.message) {
      postData.message = '';
    }

    // Vérifier si la publication est une vidéo ou une publication standard
    if (!postData.permalink_url.includes('/videos/')) {
      // Essayer de récupérer les informations spécifiques aux publications standards (non vidéo)
      try {
        const extraFieldsResponse = await axios.get(postUrl, {
          params: {
            access_token: accessToken,
            fields: 'message,attachments{media,url}',
          },
        });
        postData.message = extraFieldsResponse.data.message || postData.message;
        postData.attachments = extraFieldsResponse.data.attachments || { data: [] };
      } catch (extraFieldsError) {
        console.warn('Impossible de récupérer les champs supplémentaires pour la publication :', extraFieldsError);
        postData.attachments = { data: [] };
      }
    } else {
      // Pour les vidéos, essayer de récupérer les détails spécifiques de la vidéo
      try {
        const videoFieldsResponse = await axios.get(postUrl, {
          params: {
            access_token: accessToken,
            fields: 'description,source',
          },
        });
        postData.message = videoFieldsResponse.data.description || postData.message;
        postData.videoSource = videoFieldsResponse.data.source || '';
      } catch (videoFieldsError) {
        console.warn('Impossible de récupérer les champs spécifiques pour la vidéo :', videoFieldsError);
        postData.videoSource = '';
      }
      postData.attachments = { data: [] }; // Initialiser `attachments` pour les vidéos
    }

    return postData;
  } catch (error) {
    handleError('Erreur lors de la récupération des détails de la publication', error);
    return null;
  }
};

// Fonction pour télécharger une photo ou une vidéo sur Facebook (sans publication immédiate pour les photos)
export const uploadMediaToFacebook = async (pageId, accessToken, file, message = '') => {
  try {
    const formData = new FormData();
    formData.append('source', file);
    formData.append('access_token', accessToken);

    let url;
    if (file.type.startsWith('image/')) {
      url = `https://graph.facebook.com/${FB_API_VERSION}/${pageId}/photos`;
      formData.append('published', 'false'); // Empêche la publication immédiate pour les photos
    } else if (file.type.startsWith('video/')) {
      url = `https://graph.facebook.com/${FB_API_VERSION}/${pageId}/videos`;
      formData.append('description', message); // Ajoute le message à la vidéo
    } else {
      throw new Error(`Unsupported file type: ${file.type}`);
    }

    const mediaResponse = await axios.post(url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    // Ajouter des logs pour vérifier la réponse après téléchargement du média
    console.log('Réponse après téléchargement du média :', mediaResponse.data);

    return mediaResponse.data.id || null;
  } catch (error) {
    handleError("Erreur lors du téléchargement du média", error);
    return null;
  }
};

// Fonction pour publier un post avec texte et médias sur Facebook
export const publishPostToFacebook = async (pageId, accessToken, content, attachedMedia = []) => {
  try {
    const postPayload = {
      message: content,
      access_token: accessToken,
      ...(attachedMedia.length > 0 && { attached_media: attachedMedia }),
    };

    const postResponse = await axios.post(
      `https://graph.facebook.com/${FB_API_VERSION}/${pageId}/feed`,
      postPayload
    );

    // Ajouter des logs pour vérifier la réponse après publication du post
    console.log('Réponse après publication du post :', postResponse.data);

    showNotification("Publication réussie sur Facebook", "success");
    return postResponse.data;
  } catch (error) {
    handleError("Erreur lors de la publication sur Facebook", error);
    throw error;
  }
};

// Fonction principale pour gérer la publication de contenu avec texte et médias
export const publishContentWithMedia = async (pageId, accessToken, content, files = []) => {
  try {
    const attachedMedia = [];

    if (files.length > 0) {
      for (const file of files) {
        const mediaId = await uploadMediaToFacebook(pageId, accessToken, file, content);

        if (!mediaId) {
          throw new Error("Erreur : Impossible de récupérer un ID de média valide.");
        }

        if (file.type.startsWith('image/')) {
          attachedMedia.push({ media_fbid: mediaId });
        } else {
          showNotification("Vidéo publiée avec succès", "success");
          console.log('ID de la vidéo publiée :', mediaId);
          return { id: mediaId }; // Retour immédiat pour une vidéo publiée
        }
      }
    }

    const postResponse = await publishPostToFacebook(pageId, accessToken, content, attachedMedia);
    console.log('Réponse après publication avec médias :', postResponse);
    return postResponse;
  } catch (error) {
    handleError("Erreur lors de la publication de contenu avec média", error);
    return null;
  }
};

// Fonction centralisée de gestion des erreurs
const handleError = (message, error) => {
  console.error(`${message}:`, error);
  if (error.response) {
    const fbError = error.response.data.error;
    if (fbError) {
      showNotification(`Erreur Facebook : ${fbError.message}`, 'error');
    } else {
      showNotification("Une erreur est survenue lors de la communication avec Facebook.", 'error');
    }
  } else if (error.request) {
    showNotification("La requête n'a pas pu être envoyée. Vérifiez votre connexion internet.", 'warning');
  } else {
    showNotification("Erreur de configuration : " + error.message, 'error');
  }
};