// src/components/Card/FacebookPostCard.js
import React, { useState } from 'react';
import { Card, CardHeader, CardContent, CardActions, Button, Typography, Avatar, Box, Modal, IconButton } from '@mui/material';
import { ThumbUp, Comment, Close } from '@mui/icons-material';

function FacebookPostCard({ post }) {
  const [open, setOpen] = useState(false);

  // Destructure les données de la publication
  const {
    message,
    attachments,
    likes,
    comments,
    permalink_url,
    created_time,
    from,
    videoSource,
  } = post;

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Card sx={{ maxWidth: 600, marginBottom: 3 }}>
      <CardHeader
        avatar={<Avatar alt={from?.name || 'Page Facebook'} src={from?.picture?.data?.url || '/default-avatar.png'} />}
        title={from?.name || 'Nom de la Page Inconnu'}
        subheader={created_time ? new Date(created_time).toLocaleString() : 'Date inconnue'}
      />
      {attachments && attachments.data && attachments.data.length > 0 && (
        <Box sx={{ position: 'relative' }}>
          <img
            src={attachments.data[0].media?.image?.src || attachments.data[0].media?.source}
            alt="main-attachment"
            style={{ width: '100%', maxHeight: '400px', objectFit: 'cover' }}
          />
          <Box sx={{ display: 'flex', overflowX: 'auto', marginTop: 1 }}>
            {attachments.data.slice(1, 4).map((attachment, idx) => (
              <Box
                key={idx}
                component="img"
                src={attachment.media?.image?.src || attachment.media?.source}
                alt={`attachment-${idx}`}
                sx={{ width: 80, height: 80, objectFit: 'cover', marginRight: 1, cursor: 'pointer' }}
                onClick={handleOpen}
              />
            ))}
            {attachments.data.length > 4 && (
              <Button onClick={handleOpen} sx={{ marginLeft: 1 }}>
                Voir plus
              </Button>
            )}
          </Box>
        </Box>
      )}
      {videoSource && (
        <Box sx={{ position: 'relative', marginBottom: 2 }}>
          <video controls style={{ width: '100%', maxHeight: '400px' }} src={videoSource} />
        </Box>
      )}
      <CardContent>
        {message && (
          <Typography variant="body2" color="text.secondary">
            {message}
          </Typography>
        )}
      </CardContent>
      <CardActions disableSpacing>
        <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 2 }}>
          <ThumbUp sx={{ marginRight: 1 }} />
          <Typography variant="body2">{likes?.summary?.total_count || 0}</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 2 }}>
          <Comment sx={{ marginRight: 1 }} />
          <Typography variant="body2">{comments?.summary?.total_count || 0}</Typography>
        </Box>
        <Button
          size="small"
          color="primary"
          onClick={() => window.open(permalink_url, '_blank')}
        >
          Voir sur Facebook
        </Button>
      </CardActions>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: 600,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: 'absolute', top: 8, right: 8 }}
          >
            <Close />
          </IconButton>
          {attachments.data.map((attachment, idx) => (
            <Box key={idx} sx={{ marginBottom: 2 }}>
              {attachment.type === 'photo' && attachment.media?.image?.src && (
                <img
                  src={attachment.media.image.src}
                  alt={`attachment-${idx}`}
                  style={{ width: '100%', maxHeight: '400px', objectFit: 'cover' }}
                />
              )}
              {attachment.type === 'video' && attachment.media?.source && (
                <video
                  controls
                  style={{ width: '100%', maxHeight: '400px' }}
                  src={attachment.media.source}
                />
              )}
            </Box>
          ))}
        </Box>
      </Modal>
    </Card>
  );
}

export default FacebookPostCard;
