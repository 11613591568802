// src/services/notificationService.js
let notificationHandler = null;

export const setNotificationHandler = (handler) => {
  notificationHandler = handler;
};

export const showNotification = (message, severity = 'info') => {
  if (notificationHandler) {
    notificationHandler(message, severity);
  } else {
    console.warn("Notification handler not set.");
  }
};
