// PostCard.js
import React, { useState, useEffect } from 'react';
import { Card, CardContent, useMediaQuery, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import LoadingOverlay from './LoadingOverlay';
import CompanyInfo from './CompanyInfo';
import CardActionsTopSection from './CardActionsTopSection';
import CardActionsBottomSection from './CardActionsBottomSection';
import PostContent from './PostContent';
import MediaPreview from './MediaPreview';
import CancelEditDialog from './CancelEditDialog';
import EditPostModal from './EditPostModal';
import { updateDraftPost, uploadMediaFiles } from '../../../services/firebase/draftPostService';
import { regeneratePartialPost } from '../../../services/OpenAi/generatePostService';

function PostCard({
  content,
  mediaFiles = [],
  companyName,
  companyLogoUrl,
  timestamp,
  isGenerating,
  isPublishing,
  isPublished,
  onPublishToFacebook,
  onDelete,
  onSchedule,
  onRegenerate,
  draftId,
  onUpdate,
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [editableContent, setEditableContent] = useState(content);
  const [tempMediaFiles, setTempMediaFiles] = useState(mediaFiles);
  const [addedMediaFiles, setAddedMediaFiles] = useState([]);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
  const [isPartialGenerating, setIsPartialGenerating] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setEditableContent(content);
    setTempMediaFiles(mediaFiles);
  }, [content, mediaFiles]);

  const handleAddMedia = (newFiles) => {
    setTempMediaFiles((prevFiles) => [...prevFiles, ...newFiles]);
    setAddedMediaFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleRemoveMedia = (index) => {
    const removedFile = tempMediaFiles[index];
    setTempMediaFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setAddedMediaFiles((prevFiles) => prevFiles.filter((file) => file !== removedFile));
  };

  const handleEditToggle = () => {
    if (isEditing) {
      setTempMediaFiles(mediaFiles);
      setEditableContent(content);
      setAddedMediaFiles([]);
    }
    setIsEditing((prev) => !prev);
  };

  const handleCancelEdit = () => {
    if (addedMediaFiles.length > 0 || tempMediaFiles.length !== mediaFiles.length || editableContent !== content) {
      setIsCancelDialogOpen(true);
    } else {
      closeEditMode();
    }
  };

  const confirmCancelEdit = () => {
    setTempMediaFiles(mediaFiles);
    setEditableContent(content);
    setAddedMediaFiles([]);
    closeEditMode();
  };

  const closeEditMode = () => {
    setIsEditing(false);
    setIsCancelDialogOpen(false);
  };

  const handleContentChange = (newContent) => {
    setEditableContent(newContent);
  };

  const handlePartialRegenerate = async (selectedText) => {
    setIsPartialGenerating(true);
    try {
      const updatedContent = await regeneratePartialPost(editableContent, selectedText);
      if (updatedContent) {
        setEditableContent(updatedContent);
      }
    } catch (error) {
      console.error('Erreur lors de la régénération partielle de la publication :', error);
    } finally {
      setIsPartialGenerating(false);
    }
  };

  const handleSaveChanges = async () => {
    if (!draftId || typeof draftId !== 'string') {
      console.error('Erreur : draftId est invalide lors de la sauvegarde des modifications.');
      return;
    }
    try {
      const uploadedMediaUrls = await uploadMediaFiles(addedMediaFiles);
      const updatedMediaUrls = [
        ...tempMediaFiles.filter((file) => file.url),
        ...uploadedMediaUrls,
      ];

      await updateDraftPost(draftId, {
        mediaUrls: updatedMediaUrls,
        content: editableContent,
      });

      setTempMediaFiles(updatedMediaUrls);
      setAddedMediaFiles([]);
      if (onUpdate) {
        onUpdate(draftId, {
          content: editableContent,
          mediaUrls: updatedMediaUrls,
        });
      }
      closeEditMode();
    } catch (error) {
      console.error('Erreur lors de la sauvegarde des modifications :', error);
    }
  };

  return (
    <>
      <EditPostModal
        isOpen={isEditing}
        onClose={handleEditToggle}
        content={editableContent}
        mediaFiles={tempMediaFiles}
        companyName={companyName}
        companyLogoUrl={companyLogoUrl}
        timestamp={timestamp}
        onSave={handleSaveChanges}
        onCancelEdit={handleCancelEdit}
        isGenerating={isGenerating}
        isPublishing={isPublishing}
        onAddMedia={handleAddMedia}
        onRemoveMedia={handleRemoveMedia}
        editableContent={editableContent}
        onContentChange={handleContentChange}
        onPartialRegenerate={handlePartialRegenerate}
        isPartialGenerating={isPartialGenerating}
      />

      {!isEditing && (
        <Card
          sx={{
            boxShadow: 2,
            borderRadius: 4,
            backgroundColor: '#f9f9f9',
            width: '100%',
            maxWidth: '100%',
            position: 'relative',
          }}
        >
          {isGenerating || isPublishing ? <LoadingOverlay /> : null}
          <CardActionsTopSection
            isEditing={isEditing}
            isPublished={isPublished}
            onDelete={onDelete}
            onEditToggle={handleEditToggle}
            onCancelEdit={handleCancelEdit}
          />
          <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 2 }}>
            <CompanyInfo companyName={companyName} companyLogoUrl={companyLogoUrl} timestamp={timestamp} />
            <PostContent content={content} isGenerating={isGenerating} isEditing={false} />
            <MediaPreview mediaFiles={mediaFiles} isEditing={false} />
          </CardContent>
          <CardActionsBottomSection
            isEditing={isEditing}
            isPublished={isPublished}
            isPublishing={isPublishing}
            onPublishToFacebook={onPublishToFacebook}
            onSchedule={onSchedule}
            isMobile={isMobile}
          />
        </Card>
      )}
      <CancelEditDialog
        isOpen={isCancelDialogOpen}
        onClose={() => setIsCancelDialogOpen(false)}
        onConfirm={confirmCancelEdit}
      />
    </>
  );
}

export default PostCard;