import React from 'react';
import { Drawer } from '@mui/material';
import DrawerContent from './DrawerContent';

const DrawerComponent = ({ isMobile, mobileOpen, onDrawerToggle, onLogout, onProfileClick }) => {
  const handleMenuItemClick = () => {
    if (isMobile) {
      onDrawerToggle(); // Ferme le Drawer en mode mobile
    }
  };

  return (
    <Drawer
      variant={isMobile ? 'temporary' : 'permanent'}
      open={isMobile ? mobileOpen : true}
      onClose={onDrawerToggle}
      sx={{
        width: isMobile ? 300 : 240, // Largeur ajustée pour mobile
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: isMobile ? 300 : 240,
          boxSizing: 'border-box',
          borderRadius: isMobile ? 0 : '16px',
          boxShadow: isMobile ? 'none' : '0px 8px 24px rgba(0, 0, 0, 0.15)',
          backgroundColor: 'white',
          margin: isMobile ? 0 : '16px',
          height: isMobile ? '100%' : 'calc(100% - 32px)',
        },
        position: isMobile ? 'fixed' : 'relative',
      }}
      ModalProps={{ keepMounted: true }}
    >
      <DrawerContent
        onLogout={onLogout}
        onMenuItemClick={handleMenuItemClick} // Passe la fonction de fermeture
        onProfileClick={onProfileClick} // Passe l'action pour ouvrir le menu utilisateur
      />
    </Drawer>
  );
};

export default DrawerComponent;