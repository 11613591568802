import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './config/style/theme'; // Import du thème personnalisé

import HomePage from './pages/HomePage/HomePage';
import SignUpPage from './pages/register/SignUpPage';
import LoginPage from './pages/register/LoginPage';
import DashboardPage from './pages/Dashboard/DashboardPage';
import ProfilePage from './pages/Dashboard/sections/Profile/ProfilePage';
import PostHistoryPage from './pages/Dashboard/sections/PostHistory/PostHistoryPage';
import NewPostPage from './pages/Dashboard/sections/NewPost/NewPostPage';
import ProtectedRoute from './components/ProtectedRoute';
import { loadFacebookSdk } from './services/socialNetworks/facebook/facebookSdk';
import { NotificationProvider } from './components/Notification/Notification'; // Import du NotificationProvider

function App() {
  useEffect(() => {
    loadFacebookSdk();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <NotificationProvider> {/* Envelopper l'application avec NotificationProvider */}
        <CssBaseline />
        <Router>
          <div className="main-container">
            <Routes>
              {/* Routes publiques */}
              <Route path="/" element={<HomePage />} />
              <Route path="/signup" element={<SignUpPage />} />
              <Route path="/login" element={<LoginPage />} />

              {/* Routes protégées du tableau de bord */}
              <Route path="/dashboard" element={<ProtectedRoute><DashboardPage /></ProtectedRoute>}>
                <Route path="profile" element={<ProfilePage />} />
                <Route path="post-history" element={<PostHistoryPage />} />
                <Route path="new-post" element={<NewPostPage />} />
              </Route>
            </Routes>
          </div>
        </Router>
      </NotificationProvider>
    </ThemeProvider>
  );
}

export default App;
