import React, { useState, useEffect, useRef } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { Container, Grid, CircularProgress, Box } from '@mui/material';
import PostCard from '../../../../components/Card/PostCard/PostCard';
import PromptInput from '../../../../components/PromptInput/PromptInput'; // Import de PromptInput
import { fetchCompanyInfo } from '../../../../services/firebase/companyService';
import { fetchFacebookPageInfo } from '../../../../services/socialNetworks/facebook/facebookPageService';
import { publishContentWithMedia } from '../../../../services/socialNetworks/facebook/facebookPostService';
import { fetchDraftPosts, deleteDraftPost } from '../../../../services/firebase/draftPostService';
import { saveFacebookPostHistory } from '../../../../services/firebase/facebookPostHistoryService';
import { auth } from '../../../../config/firebaseConfig';
import { generatePost, regeneratePost } from '../../../../services/OpenAi/generatePostService';

function NewPostPage() {
  const [isAuthLoading, setIsAuthLoading] = useState(true);
  const [companyName, setCompanyName] = useState('');
  const [companyLogoUrl, setCompanyLogoUrl] = useState('');
  const [textContent, setTextContent] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [generatedPosts, setGeneratedPosts] = useState([]);
  const [mediaFiles, setMediaFiles] = useState([]);
  const [pageId, setPageId] = useState('');
  const [pageAccessToken, setPageAccessToken] = useState('');

  const scrollContainerRef = useRef(null);
  const promptInputRef = useRef(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const companyData = await fetchCompanyInfo(user.uid);
        if (companyData) {
          setCompanyName(companyData.name || 'Nom de l’entreprise');
          setCompanyLogoUrl(companyData.logoUrl || '');
        }
        const pageData = await fetchFacebookPageInfo();
        setPageId(pageData.pageId);
        setPageAccessToken(pageData.pageAccessToken);

        const drafts = await fetchDraftPosts();
        setGeneratedPosts(drafts);
      } else {
        console.error('Aucun utilisateur connecté.');
      }
      setIsAuthLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
    }
  }, [generatedPosts, isAuthLoading]);

  useEffect(() => {
    const handleResize = () => {
      if (promptInputRef.current) {
        promptInputRef.current.style.bottom = `${window.innerHeight - document.documentElement.clientHeight}px`;
      }
    };

    // Ajoute un écouteur pour le redimensionnement de la fenêtre
    window.addEventListener('resize', handleResize);
    // Appelle handleResize une fois pour s'assurer que le positionnement est correct au chargement
    handleResize();

    return () => {
      // Nettoyage de l'écouteur lors du démontage du composant
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (isAuthLoading) {
    return (
      <Container maxWidth="md" sx={{ mt: 5, mb: 5, position: 'relative' }}>
        <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
          <CircularProgress />
        </Grid>
      </Container>
    );
  }

  const handleGeneratePost = async () => {
    await generatePost(textContent, mediaFiles, selectedDate, setGeneratedPosts, setTextContent, setMediaFiles, setSelectedDate);
  };
  

  const handleRegeneratePost = async (postId) => {
    await regeneratePost(postId, generatedPosts, setGeneratedPosts);
  };

  const handleDeletePost = async (postIndex) => {
    const post = generatedPosts[postIndex];
    try {
      await deleteDraftPost(post.id, post.mediaUrls || []);
      setGeneratedPosts((prevPosts) => prevPosts.filter((_, index) => index !== postIndex));
    } catch (error) {
      console.error('Erreur lors de la suppression du post :', error);
    }
  };

  const handlePublishToFacebook = async (postIndex) => {
    setGeneratedPosts((prevPosts) =>
      prevPosts.map((post, index) => (index === postIndex ? { ...post, isPublishing: true } : post))
    );
    const post = generatedPosts[postIndex];
    try {
      const postResponse = await publishContentWithMedia(pageId, pageAccessToken, post.content, post.mediaFiles || post.mediaUrls || []);
      if (postResponse) {
        await deleteDraftPost(post.id, post.mediaUrls || []);
        await saveFacebookPostHistory({
          postId: postResponse.id,
          pageId,
          timestamp: new Date().toISOString(),
        });

        setGeneratedPosts((prevPosts) =>
          prevPosts.map((post, index) =>
            index === postIndex ? { ...post, isPublishing: false, isPublished: true } : post
          )
        );
      } else {
        console.warn("La publication a échoué : réponse de l'API manquante.");
      }
    } catch (error) {
      console.error("Erreur lors de la publication :", error);
      setGeneratedPosts((prevPosts) =>
        prevPosts.map((post, index) => (index === postIndex ? { ...post, isPublishing: false } : post))
      );
    }
  };

  const handleUpdatePost = (draftId, updatedData) => {
    // Mettre à jour le post modifié dans la liste des posts
    setGeneratedPosts((prevPosts) =>
      prevPosts.map((post) => (post.id === draftId ? { ...post, ...updatedData } : post))
    );
  };

  const handleRemoveMedia = (index) => {
    setMediaFiles(mediaFiles.filter((_, i) => i !== index));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        position: 'relative',
      }}
    >
      {/* Conteneur qui scrolle pour les posts générés */}
      <Box
        ref={scrollContainerRef}
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          overflowY: 'auto',
          padding: 3,
        }}
      >
        <Grid container spacing={2} sx={{ width: '100%', maxWidth: '800px' }}>
          {generatedPosts.map((post, postIndex) => (
            <Grid item xs={12} key={postIndex}>
              <Box sx={{ mb: 2 }}>
                <PostCard
                  draftId={post.id}
                  content={post.content}
                  mediaFiles={post.mediaUrls ? post.mediaUrls : post.mediaFiles || []}
                  companyName={companyName}
                  companyLogoUrl={companyLogoUrl}
                  timestamp={post.timestamp}
                  isGenerating={post.isGenerating}
                  isPublishing={post.isPublishing}
                  isPublished={post.isPublished}
                  onPublishToFacebook={() => handlePublishToFacebook(postIndex)}
                  onDelete={() => handleDeletePost(postIndex)}
                  onRegenerate={() => handleRegeneratePost(post.id)}
                  onUpdate={handleUpdatePost} // Passer la fonction pour la mise à jour des posts après modification
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Input pour générer un nouveau post */}
      <PromptInput
        ref={promptInputRef}
        textContent={textContent}
        setTextContent={setTextContent}
        mediaFiles={mediaFiles}
        setMediaFiles={setMediaFiles}
        generatePost={handleGeneratePost}
        handleRemoveMedia={handleRemoveMedia}
        selectedDate={selectedDate}
        setSelectedDate = {setSelectedDate}
      />
    </Box>
  );
}

export default NewPostPage;
