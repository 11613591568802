// src/services/firebase/facebookPostHistoryService.js
import { db } from '../../config/firebaseConfig';
import { collection, addDoc, getDocs, query, where  } from 'firebase/firestore';
import { auth } from '../../config/firebaseConfig'; // Import de l'authentification

// Fonction pour sauvegarder une publication après sa publication réussie
export const saveFacebookPostHistory = async ({ postId, pageId, timestamp }) => {
  try {
    const user = auth.currentUser;
    if (!user || !user.uid) {
      throw new Error('Utilisateur non authentifié. UID est requis pour sauvegarder lhistorique de la publication Facebook');
    }

    // Référence à la collection `facebookPostHistory` (non liée à un utilisateur spécifique)
    const facebookPostsCollection = collection(db, 'facebookPostHistory');

    await addDoc(facebookPostsCollection, {
      uid: user.uid, // Ajout de l'UID pour lier le post à un utilisateur
      postId,
      pageId,
      timestamp,
      platform: 'facebook',
    });
  } catch (error) {
    console.error('Erreur lors de la sauvegarde de l’historique de la publication Facebook :', error);
    throw error;
  }
};


// Fonction pour récupérer l'historique des publications de l'utilisateur actuel
export const getFacebookPostHistory = async () => {
  try {
    const user = auth.currentUser;
    if (!user) {
      throw new Error('Utilisateur non connecté.');
    }

    const q = query(
      collection(db, 'facebookPostHistory'),
      where('uid', '==', user.uid)
    );

    const querySnapshot = await getDocs(q);
    const postHistory = [];
    querySnapshot.forEach((doc) => {
      postHistory.push(doc.data());
    });

    return postHistory;
  } catch (error) {
    console.error('Erreur lors de la récupération de l’historique des publications :', error);
    throw error;
  }
};
