// preferencesService.js
import { db, auth } from '../../config/firebaseConfig';
import { collection, doc, setDoc, getDoc } from 'firebase/firestore';

// Function to save publication preferences
export const savePreferences = async (preferences) => {
  try {
    const user = auth.currentUser;
    if (!user) throw new Error("User not authenticated");

    const preferencesRef = doc(collection(db, 'userPreferences'), user.uid);
    await setDoc(preferencesRef, preferences, { merge: true });

    console.log('Préférences enregistrées avec succès');
  } catch (error) {
    console.error('Erreur lors de l’enregistrement des préférences :', error);
    throw error;
  }
};

// Function to fetch publication preferences for the authenticated user
export const fetchPreferences = async () => {
  try {
    const user = auth.currentUser;
    if (!user) throw new Error("User not authenticated");

    const preferencesRef = doc(collection(db, 'userPreferences'), user.uid);
    const preferencesDoc = await getDoc(preferencesRef);

    if (preferencesDoc.exists()) {
      console.log('Préférences récupérées avec succès');
      return preferencesDoc.data();
    } else {
      console.log('Aucune préférence trouvée pour cet utilisateur');
      return null;
    }
  } catch (error) {
    console.error('Erreur lors de la récupération des préférences :', error);
    throw error;
  }
};

// Example of using preferences
export const examplePreferences = {
  textLength: 100,
  tone: '',
  brandValues: '',
  languageStyle: '',
  targetAudience: '',
  callToActionStyle: '',
  visualStyle: '',
  humorPersonality: '',
  signature: 'Votre ami de Viralist',
  smileyUsage: 50, // Slider to indicate the amount of smileys in the publication (0 to 100)
  language: 'Francais', // Default language (Francais, Anglais, Espagnol)
};
