import React, { useEffect, useState, forwardRef } from 'react';
import { Box, TextField, IconButton, Button, CircularProgress } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import PreferencesModal from '../Modal/PreferencesModal';
import ScheduleDatePicker from '../DatePicker/ScheduleDatePicker';

const PromptInput = forwardRef(({
  textContent,
  setTextContent,
  mediaFiles,
  setMediaFiles,
  generatePost,
  isPublishing,
  selectedDate,
  setSelectedDate,
  ...rest // Props restantes
}, ref) => {
  const [isPreferencesModalOpen, setIsPreferencesModalOpen] = useState(false);
  const [bottomPadding, setBottomPadding] = useState(16);

  useEffect(() => {
    const updatePadding = () => {
      const isMobile = window.innerWidth <= 600;
      setBottomPadding(isMobile ? 130 : 0);
    };

    updatePadding();
    window.addEventListener('resize', updatePadding);

    return () => {
      window.removeEventListener('resize', updatePadding);
    };
  }, []);

  // Supprimer un média sélectionné
  const handleRemoveMedia = (index) => {
    const updatedMediaFiles = [...mediaFiles];
    updatedMediaFiles.splice(index, 1);
    setMediaFiles(updatedMediaFiles);
  };

  // Filtrer les props restantes pour éviter les erreurs
  const { handleRemoveMedia: _, ...filteredRest } = rest;

  return (
    <Box
      ref={ref}
      {...filteredRest} // Seules les props filtrées sont passées
      sx={{
        position: 'sticky',
        bottom: 0,
        left: 0,
        right: 0,
        boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.2)',
        p: 2,
        backgroundColor: 'background.paper',
        borderRadius: '8px 8px 0 0',
        zIndex: 1000,
        width: '100%',
        maxWidth: '800px',
        margin: '0 auto',
        paddingBottom: `calc(env(safe-area-inset-bottom) + ${bottomPadding}px)`,
        transition: 'all 0.3s ease',
      }}
    >
      {/* Barre d'actions */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        {/* Bouton Préférences */}
        <Button
          variant="outlined"
          onClick={() => setIsPreferencesModalOpen(true)}
          sx={{
            height: 48,
            minWidth: 120,
            borderRadius: 2,
          }}
        >
          Préférences
        </Button>

        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          {/* Composant Date Picker */}
          <ScheduleDatePicker
            selectedDate={selectedDate}
            onDateChange={setSelectedDate}
          />

          {/* Champ d'ajout de médias */}
          <input
            accept="image/*,video/*"
            style={{ display: 'none' }}
            id="upload-media"
            multiple
            type="file"
            onChange={(e) => setMediaFiles([...mediaFiles, ...Array.from(e.target.files)])}
          />
          <label htmlFor="upload-media">
            <IconButton
              component="span"
              sx={{
                color: 'primary.main',
                '&:hover': {
                  color: 'primary.dark',
                },
              }}
            >
              <AttachFileIcon />
            </IconButton>
          </label>

          {/* Bouton de publication */}
          <IconButton
            onClick={generatePost}
            disabled={!textContent.trim() || isPublishing}
            sx={{
              color: textContent.trim() ? 'primary.contrastText' : 'grey.500',
              backgroundColor: textContent.trim() ? 'primary.main' : 'grey.300',
              '&:hover': {
                backgroundColor: textContent.trim() ? 'primary.dark' : 'grey.300',
              },
              width: 48,
              height: 48,
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {isPublishing ? <CircularProgress size={24} color="inherit" /> : <ArrowForwardIcon />}
          </IconButton>
        </Box>
      </Box>

      {/* Champ texte */}
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Décrivez votre promo ou produit..."
        value={textContent}
        onChange={(e) => setTextContent(e.target.value)}
        multiline
        maxRows={6}
        sx={{ bgcolor: 'transparent', borderRadius: 2 }}
      />

      {/* Aperçu des médias ajoutés */}
      <Box sx={{ display: 'flex', gap: 1, mt: 2, flexWrap: 'wrap', pb: 2 }}>
        {mediaFiles.map((file, index) => (
          <Box key={index} sx={{ position: 'relative', width: 70, height: 70 }}>
            {file.type.startsWith('image') ? (
              <img
                src={URL.createObjectURL(file)}
                alt={file.name}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  borderRadius: 4,
                }}
              />
            ) : (
              <video
                src={URL.createObjectURL(file)}
                controls
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: 4,
                }}
              />
            )}
            <IconButton
              size="small"
              sx={{
                position: 'absolute',
                top: 2,
                right: 2,
                backgroundColor: 'rgba(0,0,0,0.5)',
              }}
              onClick={() => handleRemoveMedia(index)}
            >
              <CloseIcon style={{ color: '#fff', fontSize: 16 }} />
            </IconButton>
          </Box>
        ))}
      </Box>

      {/* Modal des préférences */}
      <PreferencesModal
        open={isPreferencesModalOpen}
        onClose={() => setIsPreferencesModalOpen(false)}
      />
    </Box>
  );
});

export default PromptInput;
