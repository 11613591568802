import React, { useState } from 'react';
import { Box, Toolbar, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Outlet, useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../../config/firebaseConfig';
import AppBarComponent from './components/AppBarComponent';
import DrawerComponent from './components/DrawerComponent';

function DashboardPage() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileOpen, setMobileOpen] = useState(false); // État pour le menu principal (Drawer)

  const handleLogout = async () => {
    try {
      await signOut(auth);
      localStorage.removeItem('userToken');
      navigate('/login');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box
      display="flex"
      height="100vh"
      overflow="hidden" // Désactive tout scroll sur le conteneur principal
    >
      {/* AppBar visible uniquement en mode mobile */}
      {isMobile && <AppBarComponent onDrawerToggle={handleDrawerToggle} />}
      
      {/* Drawer principal */}
      <DrawerComponent
        isMobile={isMobile}
        mobileOpen={mobileOpen}
        onDrawerToggle={handleDrawerToggle}
        onLogout={handleLogout}
      />

      {/* Contenu principal avec gestion du scroll local */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          overflowY: 'auto', // Autorise uniquement le scroll vertical ici
          overflowX: 'hidden',
          height: '100vh',
        }}
      >
        {isMobile && <Toolbar />}
        <Outlet />
      </Box>
    </Box>
  );
}

export default DashboardPage;
